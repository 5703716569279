<template>
  <div> 
    <b-row>
      <b-col lg="8" sm="6">
        <b-card>
          <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
            <b-table-simple
              striped
              stacked="md"
              class="mt-2 responsive text-center"
              id="tableAccept"
              :item="fetchNotifications"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Título</b-th>
                  <b-th>Assunto</b-th>
                  <b-th>Data de Criação</b-th>
                  <b-th>Ação</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in notifications">
                  <b-td>{{ tr.title }}</b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-view
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </b-td>
                  <b-td>{{ tr.created_at | dateFormat }}</b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-warning"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-update
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-delete
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableAccept"
              class="mt-1"
            />
          </b-overlay>
        </b-card>
      </b-col>
       <b-col lg="4" sm="6">
        <b-card>
          <h3 class="text-center">Título da mensagem</h3>
          <b-form-input id="title" placeholder="Por favor insira o título da mensagem" v-model="dateNotification.title"/>  
          <hr />
          <h3 class="text-center overflow-auto">Mensagem</h3>
          <b-form-textarea 
            id="value2" 
            rows="12"
            v-model="dateNotification.message"
          >
          </b-form-textarea>
          <!-- <b-row class="mt-1">
            <b-col>
              <label> Data de Inicio </label>
              <b-form-datepicker
                id="datepicker-placeholder1"
                placeholder="Data Final"
                local="pt-BR"
                v-model="dateNotification.start_date"
              />
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col>
              <label> Data Final </label>
              <b-form-datepicker
                id="datepicker-placeholder"
                placeholder="Data de Inicio"
                local="pt-BR"
                v-model="dateNotification.end_date"
              />
            </b-col>
          </b-row> -->
          <b-row class="m-1">
            <b-button variant="gradient-primary" block @click="storeNotification">
              Cadastrar
            </b-button>
            <b-button variant="gradient-danger" block> Limpar </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Modal de delete -->
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"
      ref="modal-delete"
      centered
      title="Deletar"
    >
      <b-card-text>
        Você está preste a deletar esta mensagem do sistema:
        <hr>
        <p> <b>Titulo: </b>{{notificationsModel ? notificationsModel.title : ''}} </p>
        <p> <b>Mensagem: </b>{{notificationsModel ? notificationsModel.message : ''}} </p>
        <!-- <p> <b>Data de Inicio e final: </b>{{notificationsModel ? notificationsModel.start_date : '' | dateFormat}} - {{notificationsModel ? notificationsModel.end_date : '' | dateFormat}} </p> -->
        <hr>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          unchecked-value="null"
        >
          Concordo em excluir esta mensagem
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == null"
            @click="deleteNotification(notificationsModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>

    <!-- Modal de View -->
    <b-modal
      id="modal-view"
      modal-class="modal-primary"
      ref="modal-view"
      centered
      title="Mensagem"
    >
      <b-card-text>
        <hr>
        <p> <b>Mensagem: </b>{{notificationsModel ? notificationsModel.message : ''}} </p>
        <hr>
      </b-card-text>
      <template #modal-footer>
        Mensagem referente ao {{notificationsModel ? notificationsModel.title : ''}}
      </template>
    </b-modal>

    <!-- Modal de editar -->
    <b-modal
      id="modal-update"
      ok-variant="warning"
      modal-class="modal-warning"
      ref="modal-update"
      centered
      title="Edição"
    >
      <b-card-text>
        Você está preste a editar esta farmácia do sistema:
        <hr>
      </b-card-text>
      <div v-if="notificationsModel">
        <label class="mt-1"> Novo Título </label>
        <b-form-input
          placeholder="Novo Título"
          id="title"
          @change="newTitleNotification"
          :value="dataNotification.title  = notificationsModel.title"
        >
          {{notificationsModel.name}}
        </b-form-input>
        <label class="mt-1"> Nova Mensagem</label>
        <b-form-textarea
          id="message"
          @change="newMessageNotification"
          :value="dataNotification.message = notificationsModel.message"
          rows="10"
        >
          {{notificationsModel.message}}
        </b-form-textarea>
      </div>
      <div v-else>
        Farmácia não encontrada
      </div>
      <hr>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-warning"
            block
            :value="updateNotification.id = notificationsModel.id"
            @click="updateNotifications"
          >
            Editar
          </b-button>
        </b-col>
      </template>
    </b-modal>

  </div>
  
  
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      dateNotification: {
        title: null,
        message: null,
        status: 'M',
        start_date: null,
        end_date: null
      },

      dataNotification: {
        id: null,
        title: 'title',
        message: 'mensagem',
        status: 'M',
        // start_date: '00-00-0000',
        // end_date: '00-00-0000'
      },

      updateNotification: {
        id: null,
        title: 'title',
        message: 'mensagem',
        status: 'M',
        // start_date: '00-00-0000',
        // end_date: '00-00-0000'
      },
      

      DeleteStatus: null,

      notificationsModel: null,

      id_delete: null,

    };
  },
  computed: {
    ...mapState("notification", ["notifications", "pagination"]),
  },
  methods: {
    ...mapActions("notification", ["store", "update"]),
    storeNotification() {
      this.store({
        ...this.dateNotification,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchNotifications()
      });
    },
    // updateNotifications() { 
    //   this.update({
    //     ...this.updateNotification,
    //   });
    // },
    updateNotifications(id) { 
      let data = {
        ...this.updateNotification,
      }; 
      this.$store.dispatch("notification/update", data)
      .then(() => { 
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'warning'
          },
        })   
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchNotifications()
        this.$refs['modal-update'].hide()
      });
    },
    showDetail(id){
      this.notificationsModel = this.$store.getters['notification/getNotificationById'](id)
    },
    fetchNotifications(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("notification/fetchNotifications", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
          status: 'M',
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteNotification(id) { 
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("notification/delete", data)
      .then(() => { 
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
        this.DeleteStatus = null;  
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchNotifications()
        this.$refs['modal-delete'].hide()
      });
    },
    handlePageChange(value) {
      this.fetchNotifications(true, value);
    },
    newDeleteStatus(value){
      this.DeleteStatus = value;
    },

    newTitleNotification(value){
      this.updateNotification.title = value;
    },
    newMessageNotification(value){
      this.updateNotification.message = value;
    },
    newStatusNotification(value){
      this.updateNotification.status = value;
    },
    newStartNotification(value){
      this.updateNotification.start_date = value;
    },
    newEndNotification(value){
      this.updateNotification.end_date = value;
    },
  

  },
  created() {
    this.fetchNotifications();
  },
  watch: {
    //  search(val) {
    //   this.currentPage = 1;
    //   this.fetchElderly(true, 1);
    // },

  },
};
</script>

<style>
  .limit-text{
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
